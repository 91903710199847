<template>
  <div class="about" >
    <div class="d-flex flex-column min-vh-100">
    <Menubar msg="test" />
      <div style="padding:10rem;">
        <h6>Sorry, our site is under construction.</h6>
      </div>
    <div class="wrapper flex-grow-1"></div>      
    <Footbar msg="test" /> 
    </div>
  </div>
</template>
<script>
import Menubar from '@/components/Menubar.vue'
import Footbar from '@/components/Footbar.vue'
export default {
  name: 'About',
  components: {
    Menubar,
    Footbar
  }, 
  props: {
    msg: String
  }
}
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

